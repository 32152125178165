.App {
  text-align: center;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

.page {
  margin: 20px;
  height: 100vh;
  width: 100vw;
}

.centered {
  text-align: center;
}

.quadrant {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.absolute_centered {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}