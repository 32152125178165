
.fullHeight {
    height: 100vh;
}

.pageSection {
    width: 100vw;
    background-size: cover;
    background-attachment: fixed;
    padding: 20px 10vw;
}

.interstitial {
    width: 100vw;
    padding: 20px 10vw;
    background-size: cover;
    background-attachment: fixed;
    padding: "0 5vw 0 5vw";
}

.hovercraft {
    position: absolute;
    top: calc(100vh - 40px);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    animation: hover 1.5s ease-in-out alternate infinite;
    opacity: 0;
}

@keyframes hover {
    from {
        top: calc(100vh - 60px);
    }
    to {
        top: calc(100vh - 100px);
    }
}